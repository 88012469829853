
























// Components
import { Component, Vue } from 'vue-property-decorator';
import BarChart from '@/components/charts/barChart';

// GraphQL
import dashboardAppsyncClient from '@/lib/appsync/dashboard';
import getDashboardData from '@/graphql/getDashboardData.graphql';

// Dates
import { addMinutes, startOfDay } from 'date-fns';

import { namespace } from 'vuex-class';
import MainTimeOption from '@/interfaces/mainTimeOption';  // eslint-disable-line
import { formatDate } from '@/utils/dateHelpers'; // eslint-disable-line

const appModule = namespace('appModule');

@Component({
  components: {
    BarChart,
  },
})
export default class CentralLoggingErrorsChart extends Vue {
  @appModule.Getter
  public currentTimeOption!: MainTimeOption

  public centralLoggingChart: {
    labels: string[],
    datasets: {
      label: string,
      backgroundColor: string,
      borderColor?: string,
      data: (number|null)[],
    }[],
  } = {
    labels: [],
    datasets: [],
  };

  public barChartOptions = {
    tooltips: {
      displayColors: true,
      callbacks: {
        title: () => '',
      },
    },
    scales: {
      xAxes: [{
        stacked: true,
        gridLines: {
          display: false,
        },
      }],
      yAxes: [{
        stacked: true,
        ticks: {
          beginAtZero: true,
          fontColor: '#C7C6D7',
          fontStyle: 'bold',
          maxTicksLimit: 8,
        },
      }],
    },
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      position: 'bottom',
      labels: {
        fontColor: '#C7C6D7',
      },
      onClick: (e) => e.stopPropagation(),
    },
  };

  public loading: boolean = false;

  public async fetchData(): Promise<void> {
    this.loading = true;

    /*
      Fetch central logging
     */
    const {
      data:
        {
          get_dashboard_data: {
            // results: centralLoggingResults,
            daily_error_counts: errorCounts,
          },
        },
    } = await dashboardAppsyncClient.query({
      query: getDashboardData,
      variables: {
        input: {
          start_date:
            formatDate(startOfDay(addMinutes(
              new Date(), new Date().getTimezoneOffset() - this.currentTimeOption.value * 1440,
            )), "yyyy-MM-dd'T'HH:mm:ss"),
          end_date: formatDate(startOfDay(addMinutes(new Date(), new Date().getTimezoneOffset())),
            "yyyy-MM-dd'T'HH:mm:ss"),
          env: 'prod',
          record_type: 'central_logging',
        },
      },
    });
    const parsedErrorCounts = JSON.parse(errorCounts);
    this.centralLoggingChart.labels = Object.keys(Object.values(parsedErrorCounts)[0] as string);
    const keys = Object.keys(parsedErrorCounts);
    const charts: {
      label: string,
      backgroundColor: string,
      data: (number|null)[],
    }[][] = [];
    Object.values(parsedErrorCounts).map((i: any, idx) => charts.push([
      {
        label: `${keys[idx]}-errors`,
        backgroundColor: ['#FF8000', '#AE35E1', '#00C330', '#FFC300', '#00C4FF', '#FF0000'][idx],
        data: Object.values(i).map((item: any) => item.error_count),
      },
    ]));
    this.centralLoggingChart.datasets = charts.flat();
    this.loading = false;
  }

  async created() {
    await this.fetchData();
    this.$eventBus.$on('timeAppChanged', () => {
      this.fetchData();
    });
  }
}
